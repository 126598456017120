<template>
  <div>
    <div v-if="loading" class="loading-container pt-12">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
    <v-form
      @submit.prevent="save"
      ref="form"
      v-show="!loading"
      :disabled="$attrs.disabled"
    >
      <ErrorMessages :messages="errors"></ErrorMessages>
      <v-text-field
        v-model="form.name"
        label="Nome"
        :rules="$rules.required"
        dense
        outlined
      ></v-text-field>

      <slot></slot>
    </v-form>
  </div>
</template>
<style scoped>
.v-form {
  max-width: 600px;
}
</style>
<script>
import Api from '@/resources/NajaSocialApi'
import ErrorMessages from '@/components/ErrorMessages'

export default {
  name: 'UsersForm',
  components: { ErrorMessages },
  data: () => ({
    saveLoading: false,
    loading: false,
    operations: [],
    errors: [],
    form: {
      name: null,
    },
  }),

  mounted() {
    this.init()
  },

  methods: {
    remove() {
      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.roles
            .destroy(this.$route.params.id)
            .then(async () => {
              this.$router.push({ name: 'Profiles' })
            })
            .catch(error => {
              if (error.response) {
                this.$toast.show('Erro ao remover Perfil de Acesso', 'error')
                // this._setErrors(error.response.data.errors);
              } else {
                throw error
              }
            })
            .finally(() => {
              this.$refs.removeQuestionDialog.close()
            })
        },
      })
    },

    init() {
      if (this.$route.params.id) {
        this.fetch(this.$route.params.id)
      }
    },

    _parseForm(data) {
      this.form = { ...this.form, ...data }
    },

    fetch(id) {
      this.loading = true
      Api.roles
        .fetch(id)
        .then(async response => {
          this._parseForm(response.data.role)
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast('Erro ao buscar Perfil de Acesso')

            this.loading = false
          } else {
            throw error
          }
        })
        .finally(() => {
          this.$scrollTop()
          this.loading = false
        })
    },

    save() {
      this.saveLoading = true
      const data = {
        role: {
          name: this.form.name,
        },
      }

      Api.roles
        .save(this.$route.params.id, data)
        .then(response => {
          this._parseForm(response.data.role)
          this.$root.$children[0].toast('Perfil de Acesso salvo com sucesso')

          this.$router.push({
            name: 'ProfileShow',
            params: { id: this.form.id },
          })
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast('Erro ao salvar Perfil de Acesso')
            if (error.response.status != 500) {
              this.errors = error.response.data.errors
              this.$refs.form.validate()
            }
          } else {
            throw error
          }

          // throw error;
        })
        .finally(() => {
          this.$scrollTop()
          this.saveLoading = false
        })
    },
  },
}
</script>
